import React, { useState, useRef, useEffect } from 'react';
import { Button, LinkButton, TextInput, NMCKEditor, UploadIcon } from '../../../../components';
import { UploadBlueprint } from './UploadBlueprint';
import { ViewBlueprint } from './ViewBlueprint';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ArcActions, getTaskBlueprintData, isBlueprintActive, isBlueprintArchived } from '../../../../store';
import { BreadcrumbComponent } from './Helpers';

const Sections = [
    { title: "Description: ", attribute: 'description' },
    { title: "Internal Notes: ", attribute: 'internal_notes' }
]
export const CreateNewTaskScreen = (props) => {
    const dispatch = useDispatch();
    const { edit, readOnly, create } = props;
    const ckRefs = useRef({})
    const [state, setState] = useState({
        blueprintName: '',
        showPublishDialog: false,
        showEditPage: false,
        blueprintData: [],
        enableUploadBlueprintButton: false,
    })
    const url = window.location.href;
    const regex = /\/A\/([a-f0-9]{32})/;
    const match = url.match(regex);
    const guid = (match && match[1]) ? match[1] : ''
    const blueprint = useSelector(getTaskBlueprintData)
    const isArchive = isBlueprintArchived(blueprint) || false
    const isActive = isBlueprintActive(blueprint) || false

    useEffect(() => {
        if (!create && url && guid) {
            dispatch(ArcActions.fetchTaskBlueprint({ guid: guid }))
        }
    }, [url, guid])

    useEffect(() => {
        if (edit && blueprint) {
            setState((prevState) => ({
                ...prevState,
                blueprintName: blueprint.title || ''
            }));
        }
    }, [edit, blueprint])


    const handleEditorInstance = (attribute, e) => {
        ckRefs.current[attribute] = e.editor;
        if (Boolean(edit && blueprint)) {
            console.log(attribute, blueprint[attribute])
            e.editor.setData(blueprint[attribute])
        }
    }
    const getBlueprintData = () => {
        let blueprintData = Sections.map((section) => {
            let value = ckRefs.current[section.attribute].getData();
            return { [section.attribute]: value }
        })
        if (state.blueprintName) {
            blueprintData.push({ blueprintName: state.blueprintName })
        }
        if (blueprintData) {
            setState((_) => ({ ..._, blueprintData: blueprintData }))
        }
    }
    const handleUploadButtonClick = () => {
        getBlueprintData()
        setTimeout(() => {
            setState((_) => ({ ..._, showPublishDialog: true }))
        }, 0)
    }
    return (
        <div className='col w-100 h-100 o-hide screen-pad-con new-content doc'>
            <BreadcrumbComponent isActive={isActive} isArchive={blueprint && isBlueprintArchived(blueprint)} edit={edit} create={create} />
            {
                (!(create || edit) && blueprint) ?
                    <ViewBlueprint blueprint={blueprint} isArchive={blueprint && isBlueprintArchived(blueprint)} />
                    :
                    <div>
                        <>
                            <div className='row'>
                                <div className='flex-grow1'>
                                    <div className='d-flex v-ctr'>
                                        <div>
                                            <TextInput
                                                name='name'
                                                label='Title:'
                                                font='f18'
                                                labelClassName='w-70pix bolder exo2'
                                                className='w-900pix'
                                                labeltextclassname='exo2'
                                                value={state.blueprintName}
                                                onChange={(e) => { setState((prevState) => ({ ...prevState, blueprintName: e.target.value })) }}
                                                required={true}
                                                placeholderClassName='exo2 f20 h-40'
                                                placeholder='Compliance Blueprint Title'
                                            />
                                        </div>

                                        <div className='d-flex w-250pix ml7 mb16'>
                                            <b className='f18'>Blueprint ID:</b>
                                            <span className=' f20 ml2'>{(edit && blueprint) ? <div className='c238787'>{blueprint.blueprint_id}</div> : <div className='cFF0000'>TBD</div>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='row header h-btn mt1'>
                                        {
                                            readOnly && !isArchive && !edit &&
                                            <>
                                                <LinkButton label="Edit" link="edit" font='f9' />
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col con-editor-container">
                                <div className="editor-wrapper">
                                    {
                                        Sections.map((section) => {
                                            return (
                                                <div className='col w-100 con-ck-container pr3' key={section.attribute} id={section.attribute}>
                                                    <h6 className='f18 c00085 reg caps'>
                                                        {section.title}{section.required && <span className="cFE3333">*</span>}
                                                    </h6>
                                                    <NMCKEditor
                                                        title={section.title}
                                                        initialData={(edit && blueprint) ? (blueprint[section.attribute] || null) : undefined}
                                                        onInstanceReady={handleEditorInstance.bind(null, section.attribute)}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    <Button
                                        className="exo2 bolder f14"
                                        label='Upload Blueprint'
                                        icon = "icon-upload cFFF f14"
                                        onClick={handleUploadButtonClick}
                                        disabled={!(state.blueprintName)}
                                    />
                                    <div className='f12px mt1'><b className='cFF4444'>Note: </b>Blueprint <b className='cFF4444'>WILL NOT</b> be created if any errors are encountered. Errors information will be provided.</div>
                                </div>
                            </div>
                        </>
                        {
                            state.showPublishDialog &&
                            <UploadBlueprint
                                onClose={() => {
                                    setState((_) => ({ ..._, showPublishDialog: false }))
                                }}
                                blueprintData={state.blueprintData}
                                blueprintId={(edit && blueprint && blueprint.task_blueprint_id) ? blueprint.task_blueprint_id : ''}
                            />
                        }
                    </div>
            }
        </div>
    )
}