import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Menu, DocumentTable, Button, LinkButton } from "../../../components";
import { Tabs } from 'antd';
import { GuideActions, getCentralGuides } from '../../../store';

const Columns = [
    { title: 'Version', dataIndex: 'version_no', key: 'version_no', width: '10%', className: 'exo2 f9 ' },
    { title: 'Guide ID', dataIndex: 'guide_id', key: 'guide_id', width: '15%', className: 'exo2 f9 text-center' },
    { title: 'Module', dataIndex: 'module_name', key: 'module_name', width: '20%', className: 'exo2 f9 text-center' },
    { title: 'Title', dataIndex: 'title', key: 'title', width: '35%', className: 'exo2 f9', search: true, render: 'link' },
    { title: 'Updated', dataIndex: 'updated_at', key: 'updated_at', width: '20%', className: 'exo2 f9 p3' },

]
const getTab = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    return currentUrlParams.get('tab') || 'active'
}
export const CentralControllerGuideScreen = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [state, setState] = useState({ tab: getTab() })
    const guides = useSelector(getCentralGuides.bind(null, state.tab))

    useEffect(() => {
        dispatch(GuideActions.setShowGuide({ guide: null }))
    }, [])
    useEffect(() => {
        dispatch(GuideActions.fetchCentralGuides({ status: state.tab }))
    }, [state.tab])

    return (
        <div className='col w-100 h-100 o-hide screen-pad'>
            <div className='row docs-header h-btn'>
                <h4 className='exo2 f4 med c238787'>Guide</h4>
                {state.tab === 'draft' && <LinkButton label="New Guide" link="new" font='f9' />}
            </div>
            <div className='col f-rest o-hide'>
                <Tabs
                    activeKey={state.tab}
                    onChange={(key) => {
                        setState((_) => ({ ..._, tab: key }))
                        navigate(`${window.location.pathname}?tab=${key}`)
                    }}
                    items={[{ label: 'Active', key: 'active' }, { label: 'Draft', key: 'draft' }, { label: 'Archive', key: 'archive' }]} />
                <div className='col f-rest o-hide'>
                    <DocumentTable
                        noSelection
                        Columns={Columns}
                        rows={guides}
                        emptyMessage={state.tab === 'active' ? 'No Active Guide' : state.tab === 'archive' ? 'No Archive Guide' : 'No Draft Guide'}
                        height='h-90'
                    />
                </div>
            </div>
        </div>
    )
}
