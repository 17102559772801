import React, { useEffect, useRef, useState } from 'react';
import { AppActions, ArcActions, getBlueprintStatusResponse } from '../../../../store';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormDialog, CSVIcon, CSVBlueIcon, CloseIcon } from "../../../../components";
import { RightSquareOutlined, FolderOpenOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const UploadBlueprint = ({ onClose, blueprintData, blueprintId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uploadedBlueprintStatusResponse = useSelector(getBlueprintStatusResponse) || null
    const [state, setState] = useState({ disableNext: true, tab: 'choose', name: '' });
    const [csvContent, setCsvContent] = useState({
        fileName: '',
        fileContent: '',
        taskFailed: '',
        taskCreated: ''
    });
    const _ref = useRef(null);
    const _file = useRef(null);
    const _res = useRef(null);

    useEffect(() => {
        if (uploadedBlueprintStatusResponse && uploadedBlueprintStatusResponse.csvFileStatusResponse) {
            let csvFileResponse = uploadedBlueprintStatusResponse.csvFileStatusResponse
            let createdTasks = (csvFileResponse.total_number_tasks) - (csvFileResponse.count_not_created)
            let temp = {
                fileName: csvFileResponse.filename || '',
                fileContent: csvFileResponse.file_content || '',
                taskFailed: csvFileResponse.count_not_created || '',
                taskCreated: createdTasks || 0
            }
            setCsvContent(temp);
        }
    }, [uploadedBlueprintStatusResponse])

    const handleDragEnter = (e) => {
        e.preventDefault();
        return false;
    };
    const handleFileChange = async (e) => {
        e.preventDefault();
        let files = e.target.files;
        if (!files) {
            files = e.dataTransfer.files;
        }
        if (files.length > 1) {
            onClose();
            return dispatch(AppActions.setAlert({ success: false, message: 'Multiple files are not supported' }));
        }
        _file.current = files[0];
        _res.current = null;
        let name = _file.current.name.split('.')[0];
        setState((_) => ({ ..._, name: name, tab: 'view' }));
    };
    const handleClose = () => {
        onClose && onClose();
        if (state.tab === 'finalView') {
            navigate("/A?tab=active")
        }
    };
    const handleFileChoose = (e) => {
        if (_ref.current) {
            _ref.current.value = null;
            _ref.current.click();
        }
    };
    const handleDeleteFile = () => {
        _file.current = null;
        setState((prevState) => ({
            ...prevState,
            name: '',
            disableNext: true,
            tab: 'choose',
            rows: []
        }));
    };
    const handleNextButtonClick = () => {
        if (state.tab === 'view') {
            handlePublish()
        }
    };
    const handlePublish = () => {
        const payload = getBlueprintData()
        if (blueprintId && blueprintId !== '') {
            dispatch(ArcActions.editTaskBlueprint(payload))
        }
        else {
            dispatch(ArcActions.createTaskBlueprint(payload))
        }
        setState((_) => ({ ..._, tab: 'finalView' }));
    }
    const convertToString = (text) => {
        if (!(typeof text === 'string' || text instanceof String)) {
            return JSON.stringify(text);
        } else {
            return text;
        }
    }
    const getBlueprintData = () => {
        const body = new FormData();
        body.append('file', _file.current);
        body.append('blueprint[title]', convertToString(blueprintData[2]['blueprintName']));
        if (blueprintData[0] && blueprintData[0]['description']) {
            body.append('blueprint[description]', convertToString((blueprintData[0]['description'])));
        }
        if (blueprintData[1] && blueprintData[1]['internal_notes']) {
            body.append('blueprint[internal_notes]', convertToString((blueprintData[1]['internal_notes'])));
        }
        if (blueprintId && blueprintId !== '') {
            body.append('blueprint[task_blueprint_id]', convertToString(blueprintId));
        }
        // for (let [key, value] of body.entries()) {
        //     console.log(`${key}: ${value}`);
        // }
        return body;
    };
    const handleDownloadErrorFile = () => {
        if (uploadedBlueprintStatusResponse && csvContent) {
            const content = `data:text/csv;charset=utf-8,${csvContent.fileContent}`;
            const encodedUri = encodeURI(content);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", csvContent.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            return dispatch(AppActions.setAlert({ success: false, message: 'File can not be downloaded.' }));
        }
    };
    const getLeftButton = () => {
        let btn = null
        if (state.tab === 'choose' || state.tab === 'view') {
            btn = { label: 'Next', disabled: (state.tab === 'choose'), className: 'exo2 f14 bolder c0133CC', iconcomponent: <RightSquareOutlined className='suffix-icon-lite thick-icon-lite' />, onClick: handleNextButtonClick, variant: 'lite', color: '#0133CC' }
        }
        return btn;
    }
    const getRightButton = () => {
        let btn = { label: 'Close', className: 'exo2 f14 bolder', onClick: handleClose, color: '#0133CC', iconcomponent: <CloseCircleOutlined className='suffix-icon thick-icon' /> }
        return btn;
    }
    return (
        <>
            {
                !((Boolean(state.tab === 'finalView') && !Boolean(uploadedBlueprintStatusResponse))) && // this is to check when file upload is failed and when there is no response from the backend
                <FormDialog
                    onClose={onClose}
                    TitleComponent={() => {
                        return (
                            <div className='row'>
                                <h6 className={`f19 bold exo2 c238787`}>{(state.tab === 'showErrorPage') ? 'Upload Errors' : 'Upload Compliance Blueprint'}</h6>
                            </div>
                        );
                    }}
                    className={`upload-doc-dialog`}
                    dialogClassName={`upload-dialog-width`}
                    leftBtn={getLeftButton()}
                    rightBtn={getRightButton()}
                >
                    {
                        Boolean(state.tab === 'choose' || state.tab === 'view') &&
                        <>
                            {(state.name && state.tab === 'view') ?
                                <div className='file-display f8 d-flex h-btn mt2'>
                                    <div className='file-wrapper'>
                                        <CSVIcon className='pr1' />
                                        <span className='file-name'>{state.name}.csv</span>
                                        <i className='icon-delete cursor-pointer' onClick={handleDeleteFile} />
                                    </div>
                                </div>
                                :
                                <div
                                    className='col w-100'
                                    draggable='true'
                                    onDragOver={handleDragEnter}
                                    onDrop={handleFileChange}
                                    onDragStart={handleDragEnter}>
                                    <div className=' files-drag-drop-zone' onClick={handleFileChoose}>
                                        <div className='d-flex' >
                                            <FolderOpenOutlined className='folder-icon c0011CC' />
                                            <div>
                                                <div className='f14 c00085 reg text'>Drag & drop the prepared Compliance Blueprint file here!</div>
                                                <div className='c00045 reg text f10px'>Alternatively, clicking here will open Select File screen...</div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type='file' hidden ref={_ref} accept=".csv" multiple onChange={handleFileChange} />
                                </div>
                            }
                        </>
                    }
                    {
                        Boolean(state.tab === 'finalView') &&
                        <>
                            {
                                (csvContent && csvContent.taskFailed && csvContent.taskFailed !== 0) ?
                                    <div className='f8'>
                                        <div className='pt2'><b>{state.name}.csv</b> has <b className='cFF0000'>FAILED</b> </div>
                                        <p>Tasks with Errors: {csvContent.taskFailed ? csvContent.taskFailed : 0}</p>
                                        <p>Blueprint upload errors are available in the CSV file below.<br />
                                            Fix the errors & re-upload the Compliance Blueprint!</p>
                                        <div className='file-display c0133CC cursor-pointer' onClick={handleDownloadErrorFile}>
                                            <div className='file-wrapper'>
                                                <CSVBlueIcon className='pr1' />
                                                <span>{csvContent ? csvContent.fileName : `${state.name}_Status.csv`}</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='f8'>
                                        <div className='pt2'><b>{state.name}.csv</b> was successfully uploaded with the following results:</div>
                                        <p>Tasks in this Compliance Blueprint: <b>{csvContent.taskCreated ? csvContent.taskCreated : 0}</b></p>
                                        <p>Compliance Blueprint is activated & ready for allocation.</p>
                                    </div>
                            }
                        </>
                    }
                </FormDialog>
            }
        </>

    );
};