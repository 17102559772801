import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Breadcrumb, Tooltip } from 'antd';
import { DocumentTable, LinkButton, PlusIcon } from "../../../components";
import { ArcActions, getTaskBlueprints } from "../../../store";

const Columns = [
    // { title: 'Version', dataIndex: 'version', key: 'version', width: '10%', className: 'exo2 f9 p3 text-center' },
    { title: 'ID', dataIndex: 'blueprint_id', key: 'blueprint_id', width: '15%', className:'exo2 med f8', cellClassName: 'reg f8' },
    { title: 'Title', dataIndex: 'title', key: 'title', width: '65%', search: true, render: 'link', className:'exo2 med f8', cellClassName: 'reg f8', sorter: (a, b) => a.title.localeCompare(b.title)},
    { title: 'Updated', dataIndex: 'updated_at', key: 'updated_at', width: '20%', className:'exo2 med f8', cellClassName: 'reg f8', sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
},
]
const getTab = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    return currentUrlParams.get('tab') || 'active'
}
export const ArcControllerTaskScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState({ tab: getTab(), currentPage: 1, total: 0, blueprintsForCurrentpage: [] });
    const blueprints = useSelector(getTaskBlueprints.bind(null, state.tab))
    const _filter = React.useRef({ pageNo: 1 });
    const pageSize = 9;

    useEffect(() => {
        dispatch(ArcActions.fetchTaskBlueprints({ status: state.tab }))
    }, [state.tab])

    useEffect(() => {
        if (blueprints) {
            const total = Array.isArray(blueprints) ? blueprints.length : 0;
            const paginatedBlueprints = blueprints.slice((state.currentPage - 1) * pageSize, state.currentPage * pageSize);
            setState(prevState => ({
                ...prevState,
                total: total,
                blueprintsForCurrentpage: paginatedBlueprints,
            }));
        }
    }, [blueprints, state.currentPage]);
    const getTabItems = () => {
        let tabs = [
            {
                label: <span className={`f18 exo2 slight-bold ${state.tab === 'active' ? 'c1133CC' : 'c000'}`}>Active</span>, key: 'active'
            },
            {
                label: <span className={`f18 exo2 slight-bold ${state.tab === 'archive' ? 'c1133CC' : 'c000'}`}>Archive</span>, key: 'archive'
            }
        ];
        return tabs;
    }
    const TabItems = getTabItems();
    const handlePageChange = (pageNo) => {
        setState((prevState) => ({ ...prevState, currentPage: pageNo }));
    };
    return (
        <div className='row v-start w-100 h-100 o-hide  bgWhite' >
            <div className='h-100 w-100' style={{ backgroundColor: '' }}>
                <div className="row w-100 h-btn screen-pad" style={{ backgroundColor: '' }}>
                    <Tabs
                        items={TabItems}
                        activeKey={state.tab}
                        onChange={(key) => {
                            setState((_) => ({ ..._, tab: key }))
                            navigate(`${window.location.pathname}?tab=${key}`)
                        }}
                    />
                    <div className="mb2">
                        {
                            state.tab === 'active' &&
                            <LinkButton
                                label= 'New Blueprint'
                                link="new" font='f9' className="ptb-1 bolder exo2 bgc1133CC"
                                icon="icon-plus-lite cFFF bolder f9"
                            />
                        }
                    </div>
                </div>
                <div className="shadow-box-arc"></div>
                <div className='col h-100 o-hide screen-pad' style={{ width: '65vw' }}
                >
                    <DocumentTable
                        noSelection
                        Columns={Columns}
                        rows={state.blueprintsForCurrentpage}
                        emptyMessage={state.tab === 'active' ? 'No Active Blueprints' : 'No Archive Blueprints'}
                        onPageChange={handlePageChange}
                        totalCount={state.total}
                        pageSize={pageSize}
                        paginationClassName= 'paging-alignment'
                        height='h-90'
                    />
                </div>
            </div>
        </div>
    )
}